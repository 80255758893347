import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { FC, ReactElement } from 'react'
import styled, { css, ThemeProvider } from 'styled-components'

type BlocRowProps = {
  isHero?: boolean
  isReversed?: boolean
  children: ReactElement
}

export const BlocRow: FC<BlocRowProps> = ({ children, isHero, isReversed, ...props }) => (
  <ThemeProvider theme={{ isHero, isReversed }}>
    <Grid {...props}>
      <Row>{children}</Row>
    </Grid>
  </ThemeProvider>
)

const Grid = styled.div`
  ${grid()};
  padding-bottom: ${g(5)};
  padding-top: ${({ theme: { isHero } }) => (isHero ? g(4) : g(5))};
`

const Row = styled.div`
  ${column([2, -2])};

  ${({ theme: { isReversed } }) =>
    isReversed &&
    css`
      flex-direction: row-reverse;
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme: { isHero } }) => (isHero ? column([2, -2]) : column([3, -3]))};
  }
`
