import SelectField from '@pretto/bricks/components/form/SelectField'

import PropTypes from 'prop-types'
import { useMemo } from 'react'

const DURATION_OPTIONS = [
  { label: 'sur 10 ans', value: 120 },
  { label: 'sur 15 ans', value: 180 },
  { label: 'sur 20 ans', value: 240 },
  { label: 'sur 25 ans', value: 300 },
]

const RatesSelect = ({ children, defaultDuration, duration, mortgagors, onDurationChange, onMortgagorsChange }) => {
  const durationSelect = useMemo(() => {
    if (defaultDuration) {
      return DURATION_OPTIONS.find(({ value }) => value === defaultDuration).label
    }

    return <SelectField onChange={onDurationChange} options={DURATION_OPTIONS} value={duration} />
  }, [duration])

  const mortgagorsSelect = useMemo(
    () => (
      <SelectField
        onChange={onMortgagorsChange}
        options={[
          { label: '1 emprunteur', value: 1 },
          { label: '2 emprunteurs', value: 2 },
        ]}
        value={mortgagors}
      />
    ),
    [mortgagors]
  )

  return children({ duration: durationSelect, mortgagors: mortgagorsSelect })
}

RatesSelect.propTypes = {
  children: PropTypes.func.isRequired,
  defaultDuration: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  mortgagors: PropTypes.number.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  onMortgagorsChange: PropTypes.func.isRequired,
}

export default RatesSelect
