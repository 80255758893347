import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'

import * as S from './styles'

const RatesGraphLegend = ({ items }) => (
  <S.Legend>
    {items.map(({ label, variant }, index) => (
      <S.Item key={index}>
        <S.Dot variant={variant} />
        <Text size="small">{label.multiple ?? label}</Text>
      </S.Item>
    ))}
  </S.Legend>
)

RatesGraphLegend.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.shape({ multiple: PropTypes.string.isRequired }), PropTypes.string])
        .isRequired,
      variant: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default RatesGraphLegend
