import RatesGraphCumulativeDistribution from '@pretto/bricks/website/rates/components/RatesGraphCumulativeDistribution'
import RatesRowDistributionText from '@pretto/bricks/website/rates/components/RatesRowDistributionText'
import { components, defaultOverrides } from '@pretto/bricks/website/utility/ContentBlock'

import RatesSelectController from '@pretto/website/src/components/Rates/RatesSelectController'
import { BlocRow } from '@pretto/website/src/templates/blocs/components/BlocRow'
import { RatesPayload } from '@pretto/website/src/templates/blocs/types/RatesPayload.type'
import { applyRegion } from '@pretto/website/src/templates/tauxImmobilier/lib/applyRegion'
import { ratesDistribution } from '@pretto/website/src/templates/tauxImmobilier/lib/ratesDistribution'

type DistributionRowProps = {
  content: string
  duration: string
  isReversed?: boolean
  rates: RatesPayload
  region?: string
  title: string
}

export const DistributionRow: React.FC<DistributionRowProps> = ({
  content,
  duration,
  isReversed,
  rates,
  region,
  title,
  ...props
}) => {
  const graph = {
    component: RatesGraphCumulativeDistribution,
    props: () =>
      ratesDistribution(
        applyRegion({ rates: rates.marketRatesLatest, ratesRegion: rates.marketRatesRegionLatest, region })
      ),
  }

  const rowProps = {
    overrides: { ...defaultOverrides, Cta: { component: components.Cta, props: { align: 'left' } } },
    content,
    title,
    ...props,
  }

  return (
    <BlocRow isReversed={isReversed}>
      <RatesSelectController defaultDuration={duration ? parseInt(duration, 10) : undefined}>
        {props => <RatesRowDistributionText {...rowProps} {...props(graph)} />}
      </RatesSelectController>
    </BlocRow>
  )
}
