import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Column = styled.div`
  flex-basis: 100%;
  max-width: 100%;

  & + & {
    margin-top: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex-basis: 50%;
    padding: 0 ${g(4)};
    max-width: 50%;

    & + & {
      margin-top: 0;
    }
  }
`

export const Row = styled.div`
  & + & {
    margin-top: ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 ${g(-4)};

    & + & {
      margin-top: ${g(20)};
    }
  }
`

export const RowReverse = styled(Row)`
  flex-direction: row-reverse;

  & + ${Row} {
    margin-top: ${g(8)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-top: ${g(20)};
    }
  }
`
