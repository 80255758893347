import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Dot = styled.div`
  border-radius: ${g(3 / 4)};
  height: ${g(3 / 2)};
  margin-right: ${g(1)};
  width: ${g(3 / 2)};

  ${({ variant }) =>
    css`
      background-color: ${({ theme }) => theme.legacy.colors[variant]};
    `}
`

export const Item = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 ${g(3 / 2)};
  transition: all 600ms cubic-bezier(0.25, 1, 0.5, 1);
`

export const Legend = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -${g(3 / 2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: center;
  }
`
