import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Container = styled.div`
  cursor: ew-resize;
  position: relative;
`

export const Graph = styled.div`
  padding-left: ${g(6)};
`

export const Svg = styled.svg`
  overflow: visible;
`

export const Tooltip = styled.span`
  border-radius: ${g(1)};
  display: block;
  margin-bottom: ${g(4)};
  margin-left: ${g(-6)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: ${({ theme }) => theme.legacy.colors.neutral1.default};
    color: ${({ theme }) => theme.legacy.colors.white.default};
    margin: 0 ${g(3)};
    padding: ${g(2)};
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateY(${g(-3)});
    width: ${g(24)};
  }
`
