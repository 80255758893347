import { useBreakpoint } from '@pretto/bricks/assets/utility'
import { findRange, interpolate, useSpring } from '@pretto/bricks/core/utility/animation'
import { floor, logNormalDistribution, mapValueInRange, round } from '@pretto/bricks/core/utility/math'

import spline from '@yr/catmull-rom-spline'
import clamp from 'lodash/clamp'
import max from 'lodash/max'
import min from 'lodash/min'
import range from 'lodash/range'
import PropTypes from 'prop-types'
import { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import * as S from './styles'

const FONT_SIZE_MOBILE = 12
const FONT_SIZE_TABLET = 14

const HEIGHT = 290
const HEIGHT_OFFSET = 16
const HEIGHT_STAGE = 264

const PERCENTILE_MAXIMUM = 0.999
const PERCENTILE_MINIMUM = 0.001

const SAMPLING_Y = 5
const UNIT_FADING = 60
const UNIT_INTERVALS = 6

const Dot = ({ fill, x, y }) => {
  const {
    legacy: { colors },
  } = useContext(ThemeContext)
  const filterId = useRef(uuidv4()).current
  const pathId = useRef(uuidv4()).current
  const shadowBlurOuterId = useRef(uuidv4()).current
  const shadowOffsetOuterId = useRef(uuidv4()).current
  const shadowSpreadOuterId = useRef(uuidv4()).current

  return (
    <S.Svg x={x} y={y}>
      <defs>
        <circle cx="0" cy="0" id={pathId} r="8" />

        <filter filterUnits="objectBoundingBox" height="206.2%" id={filterId} width="206.2%" x="-53.1%" y="-40.6%">
          <feMorphology in="SourceAlpha" operator="dilate" radius="1.5" result={shadowSpreadOuterId} />
          <feOffset dx="0" dy="2" in={shadowSpreadOuterId} result={shadowOffsetOuterId} />
          <feGaussianBlur in={shadowOffsetOuterId} result={shadowBlurOuterId} stdDeviation="2" />
          <feComposite in={shadowBlurOuterId} in2="SourceAlpha" operator="out" result={shadowBlurOuterId} />

          <feColorMatrix
            in={shadowBlurOuterId}
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          ></feColorMatrix>
        </filter>
      </defs>

      <g>
        <use fill={colors.black} filter={`url(#${filterId})`} xlinkHref={`#${pathId}`} />
        <use fill={fill} stroke={colors.white} strokeWidth="3" xlinkHref={`#${pathId}`} />
      </g>
    </S.Svg>
  )
}

Dot.propTypes = {
  fill: PropTypes.string.isRequired,
  x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

const RatesGraphCumulativeDistribution = forwardRef(({ data, duration, isVisible, legend }, ref) => {
  const { isMobile } = useBreakpoint()
  const {
    legacy: { colors },
  } = useContext(ThemeContext)

  const containerClientRect = useRef(null)
  const containerRef = useRef(null)

  const [valueX, animateXTo] = useSpring(duration, { friction: 3 })
  const [valueY, animateYTo] = useSpring(0, { friction: 3 })

  const [relativeX, setRelativeX] = useState(null)

  useEffect(() => {
    window.addEventListener('resize', handleResize, false)

    resize()

    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [])

  useEffect(() => {
    animateXTo(duration)
  }, [duration])

  useEffect(() => {
    if (!isVisible) {
      return
    }

    animateYTo(1)
  }, [isVisible])

  const resize = () => {
    const { height, left: x, top: y, width } = containerRef.current.getBoundingClientRect()

    containerClientRect.current = { height, width, x, y }
  }

  const handleMouseLeave = () => {
    setRelativeX(null)
  }

  const handleMouseMove = event => {
    const { x, width } = containerClientRect.current

    const relativeX = clamp(event.clientX - x, 0, width)

    setRelativeX(relativeX)
  }

  const handleRef = node => {
    containerRef.current = node

    if (!ref) {
      return
    }

    ref.current = node
  }

  const handleResize = resize

  const inputRange = Object.keys(data)

  const minRange = min(
    Object.values(data).map(({ averageRate, sigma }) =>
      logNormalDistribution(averageRate, sigma).icdf(PERCENTILE_MINIMUM)
    )
  )

  const maxRange = max(
    Object.values(data).map(({ averageRate, sigma }) =>
      logNormalDistribution(averageRate, sigma).icdf(PERCENTILE_MAXIMUM)
    )
  )

  const unitInterval = (maxRange - minRange) / UNIT_INTERVALS

  const mean = interpolate(valueX, {
    inputRange,
    outputRange: Object.values(data).map(({ averageRate }) => averageRate),
  })

  const std = interpolate(valueX, {
    inputRange,
    outputRange: Object.values(data).map(({ sigma }) => sigma),
  })

  const clipId = uuidv4()

  const distribution = logNormalDistribution(mean, std)
  const distributedPoints = range(minRange, maxRange + 0.1, 0.1).map(index => {
    const sample = distribution.cdf(index)

    const x = mapValueInRange(index, minRange, maxRange, 0, 100)
    const y = mapValueInRange(sample * valueY, 0, 1, HEIGHT_STAGE, 0)

    return [x, y]
  })

  const points = spline.points(distributedPoints)
  const path = spline.svgPath(points)

  const midRate = (maxRange + minRange) / 2
  const selectedRate =
    relativeX !== null ? mapValueInRange(relativeX, 0, containerClientRect.current.width, minRange, maxRange) : midRate

  const fontSize = isMobile ? FONT_SIZE_MOBILE : FONT_SIZE_TABLET

  const formattedCumulate = Math.floor(distribution.cdf(selectedRate) * 100)
  const formattedSelectedRate = round(selectedRate, 2).toLocaleString('fr', { minimumFractionDigits: 2 })

  const legendIndex = findRange(selectedRate, [...legend.map(({ range }) => distribution.icdf(range[0])), maxRange])
  const dotFill = colors[legend[legendIndex].variant]

  const dotX = relativeX === null ? '50%' : relativeX
  const dotY = (1 - distribution.cdf(selectedRate)) * HEIGHT_STAGE

  const indicatorLineX = relativeX === null ? '50%' : relativeX

  const tooltipX = relativeX === null ? '50%' : containerClientRect.current.width - relativeX
  const tooltipY = dotY

  return (
    <S.Graph>
      <S.Container>
        {(isMobile || (!isMobile && relativeX !== null)) && (
          <S.Tooltip
            style={{
              right: tooltipX,
              top: tooltipY,
            }}
          >
            <strong>{formattedCumulate} %</strong> des crédits accordés ont un taux inférieur ou égal à{' '}
            <strong>{formattedSelectedRate} %</strong>
          </S.Tooltip>
        )}

        <S.Svg
          height={HEIGHT + (isMobile ? HEIGHT_OFFSET : 0)}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          ref={handleRef}
          width="100%"
        >
          {range(SAMPLING_Y).map(index => (
            <line
              key={index}
              stroke={colors.neutral1.light}
              strokeWidth="1"
              x1="0"
              x2="100%"
              y1={`${(HEIGHT_STAGE / (SAMPLING_Y - 1)) * index}`}
              y2={`${(HEIGHT_STAGE / (SAMPLING_Y - 1)) * index}`}
            />
          ))}

          <S.Svg
            height={HEIGHT_STAGE}
            preserveAspectRatio="none"
            viewBox={`0 0 100 ${HEIGHT_STAGE}`}
            width="100%"
            x="0"
            y="0"
          >
            <defs>
              <clipPath id={clipId}>
                <path d={`${path} V${HEIGHT_STAGE} H0`} fill={colors.black} />
              </clipPath>
            </defs>

            {legend.map(({ range, variant }, index) => {
              const rateMax = index === legend.length - 1 ? maxRange : distribution.icdf(range[1])
              const rateMin = index === 0 ? minRange : distribution.icdf(range[0])

              const rangeDelta = Math.max(0, maxRange - minRange)
              const rateDelta = Math.max(0, rateMax - rateMin)

              const x = mapValueInRange(rateMin, minRange, maxRange, 0, 100)
              const width = mapValueInRange(rateDelta, 0, rangeDelta, 0, 100)

              return (
                <rect
                  clipPath={`url(#${clipId})`}
                  fill={colors[variant]}
                  height="150%"
                  key={index}
                  x={x}
                  y="-50%"
                  width={width}
                />
              )
            })}
          </S.Svg>

          {(isMobile || (!isMobile && relativeX !== null)) && (
            <g>
              <line
                stroke={colors.neutral1.default}
                strokeDasharray="3"
                strokeWidth="1"
                x1={indicatorLineX}
                x2={indicatorLineX}
                y1="0"
                y2={HEIGHT_STAGE}
              />

              <Dot x={dotX} y={dotY} fill={dotFill} />
            </g>
          )}

          <text
            dominantBaseline="middle"
            fill={colors.neutral1.fade(UNIT_FADING)}
            fontSize={fontSize}
            textAnchor="end"
            x="-16"
            y="0"
          >
            100 %
          </text>

          {range(UNIT_INTERVALS + 1).map(index => {
            const x = (100 / UNIT_INTERVALS) * index

            const rate = floor(minRange + unitInterval * index, 1).toLocaleString('fr', { minimumFractionDigits: 1 })

            return (
              <S.Svg key={index} x={`${x}%`} y="100%">
                <text
                  fill={colors.neutral1.fade(UNIT_FADING)}
                  fontSize={fontSize}
                  textAnchor="end"
                  transform={isMobile ? 'rotate(45)' : ''}
                >
                  {rate} %
                </text>
              </S.Svg>
            )
          })}
        </S.Svg>
      </S.Container>
    </S.Graph>
  )
})

RatesGraphCumulativeDistribution.defaultProps = {
  isVisible: true,
}

RatesGraphCumulativeDistribution.displayName = 'RatesGraphCumulativeDistribution'

RatesGraphCumulativeDistribution.propTypes = {
  /** Dataset of graphs values. */
  data: PropTypes.object.isRequired,
  /** Duration in months used to power the animation value. */
  duration: PropTypes.number.isRequired,
  /** Whether of the graph should animate in. */
  isVisible: PropTypes.bool,
  /** Graph legend for ranges calculation. */
  legend: PropTypes.array.isRequired,
}

export default RatesGraphCumulativeDistribution
