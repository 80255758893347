import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

if (typeof window !== 'undefined') {
  require('intersection-observer')
}

export const ScrollObserver = ({ children }) => {
  const containerRef = useRef(null)

  const [hasScrolledOver, setHasScrolledOver] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            return
          }

          observer.unobserve(containerRef.current)
          setHasScrolledOver(true)
        })
      },
      {
        threshold: 0.5,
      }
    )

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (!containerRef.current) {
        return
      }

      observer.unobserve(containerRef.current)
    }
  }, [])

  return children(containerRef, hasScrolledOver)
}

ScrollObserver.propTypes = {
  children: PropTypes.func.isRequired,
}
