import { RawRate } from '@pretto/website/src/templates/tauxImmobilier/types/rawRate'
import { RawRegionRate } from '@pretto/website/src/templates/tauxImmobilier/types/rawRegionRate'

type Data = RawRate | RawRegionRate
type Result = Omit<Data, 'duration'>[] | []

export const ratesDistribution = (data: Data[]) => {
  const rates = data.reduce(
    (previous: Result, { duration, ...rate }: Data) => ({ ...previous, [duration]: rate }),
    [] as Result
  )

  return {
    data: rates,
  }
}
