import { RawRate } from '@pretto/website/src/templates/tauxImmobilier/types/rawRate'
import { RawRegionRate } from '@pretto/website/src/templates/tauxImmobilier/types/rawRegionRate'

type ApplyRegion = {
  region?: string
  rates: RawRate[]
  ratesRegion: RawRegionRate[]
}

export const applyRegion = ({ rates, ratesRegion, region }: ApplyRegion) => {
  if (!region || !ratesRegion || region === 'all') {
    return rates
  }

  return ratesRegion.filter(rate => rate.region === region)
}
