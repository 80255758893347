import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'

import { legend } from '../../../config/rates'
import RatesSelect from '../RatesSelect'

const DEFAULT_DURATION = 300
const DEFAULT_NUMBER_OF_MORTGAGORS = 2

const RatesSelectController = ({ children, defaultDuration, defaultNumberOfMorgagors }) => {
  const [customState, setCustomState] = useState({})
  const [duration, setDuration] = useState(defaultDuration ?? DEFAULT_DURATION)
  const [mortgagors, setMortgagors] = useState(defaultNumberOfMorgagors ?? DEFAULT_NUMBER_OF_MORTGAGORS)

  const handleDurationChange = ({ value }) => {
    setDuration(value)
  }

  const handleMortgagorsChange = ({ value }) => {
    setMortgagors(value)
  }

  const select = useCallback(
    ({ children }) => (
      <RatesSelect
        defaultDuration={defaultDuration}
        duration={duration}
        mortgagors={mortgagors}
        onDurationChange={handleDurationChange}
        onMortgagorsChange={handleMortgagorsChange}
      >
        {children}
      </RatesSelect>
    ),
    [duration, mortgagors]
  )

  const props = {
    duration,
    legend,
    mortgagors,
  }

  return children(({ component: Component, props: functionProps }) => {
    const componentProps = functionProps(props)

    return {
      ...props,
      ...componentProps,
      customState,
      graph: <Component {...props} {...componentProps} />,
      select,
      setCustomState,
    }
  })
}

RatesSelectController.propTypes = {
  children: PropTypes.func.isRequired,
  defaultDuration: PropTypes.number,
  defaultNumberOfMorgagors: PropTypes.number,
}

export default RatesSelectController
